import striptags from 'striptags'
import {TeacherNode} from '../../../types/content-types'
import {useTranslation} from 'next-i18next'
import {Paragraph} from '../../../types/paragraphs'
import type {Response} from '../../../types/response'

type TrainerProps = {
  isTraining?: boolean
  response?: Response | null
  trainerData?: Paragraph[]
}

const TrainerSection = ({isTraining, response, trainerData}: TrainerProps) => {
  const {t} = useTranslation()

  const renderTrainers = () => {
    const trainersArray = trainerData?.map((includedTeacher: Paragraph) => {
      const teacher = response?.included?.find(
        (el: any) => el.id === includedTeacher.id,
      ) as TeacherNode

      if (!teacher) {
        return null
      }

      return (
        <a
          href="#trainers"
          key={teacher.id}
          className="c-hero-trainer-item my-0"
        >
          {`${teacher.attributes.field_first_name} ${teacher.attributes.field_last_name}`}
        </a>
      )
    })
    return trainersArray
  }
  return (
    <>
      {isTraining && trainerData && trainerData?.length > 0 ? (
        <div className="c-hero-trainers d-flex">
          <p className="c-hero-trainers-title my-0">
            {striptags(t('trainingpage_trainers'))}
          </p>
          <div className="c-hero-trainers-container d-flex flex-row">
            {renderTrainers()}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default TrainerSection
