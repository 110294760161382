import striptags from 'striptags'
import {useTranslation} from 'next-i18next'

type InfoBlockPriceProps = {
  memberPrice?: string
  nonMemberPrice?: string
  isOnSubscriptionPage?: boolean
}

const InfoBlockPrice = ({
  memberPrice,
  nonMemberPrice,
  isOnSubscriptionPage,
}: InfoBlockPriceProps) => {
  const {t} = useTranslation()
  return (
    <div className="c-hero-info-block--price-info">
      <p className="price my-0 whitespace-nowrap">
        {striptags(t('currency_format'))} {Math.round(Number(nonMemberPrice))}
        {isOnSubscriptionPage
          ? ` / ${t('booking_priceoverview_yearlylabel')}`
          : ''}
      </p>
      {memberPrice !== nonMemberPrice ? (
        <span className="discount-text text-sm">
          {striptags(t('trainingpage_disclaimer'))}
        </span>
      ) : null}
    </div>
  )
}
export default InfoBlockPrice
