import Link from 'next/link'
import {
  getTargetFromRichtext,
  getUrlFromRichtext,
} from '../../../utils/url-from-richtext'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import striptags from 'striptags'
import {useCallback} from 'react'

declare const window: any

type TailorMadeButtonProps = {
  className?: string
  trainingId: number
}

const TailorMadeButton = ({className, trainingId}: TailorMadeButtonProps) => {
  const router = useRouter()
  const {t} = useTranslation()
  const trainingPathname = router.asPath.split('/').join('')
  const handleClick = useCallback(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'custom_training_request',
      training_id: trainingId,
    })
  }, [trainingId])

  return (
    <Link
      className={className ? className : 'btn c-training__hero_tailormade'}
      href={`${getUrlFromRichtext(
        t('trainingpage_askoffer'),
      )}?training=${trainingPathname}`}
      target={getTargetFromRichtext(t('trainingpage_askoffer'))}
      onClick={handleClick}
    >
      {striptags(t('trainingpage_askoffer'))}
    </Link>
  )
}

export default TailorMadeButton
