import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import DifficultyLevel from '../../content-types/TrainingPage/DifficultyLevel'
import striptags from 'striptags'
import SubscribeInterestedLink from '../../content-types/TrainingPage/SubscribeInterestedLink'
import TailorMadeButton from '../../content-types/TrainingPage/TailorMadeButton'
import Link247 from '../../content-types/TrainingPage/Link247'
import SubscribeLinkSubscription from '../../content-types/Subscription/SubscribeInterestedLink'
import {
  SubscriptionPageNode,
  TrainingPageNode,
} from '../../../types/content-types'
import InfoBlockLanguage from './InfoBlockLanguage'
import InfoBlockField from './InfoBlockField'
import InfoBlockPrice from './InfoBlockPrice'
import {formattedCourseType} from '../../../utils/course-type-code'

type InfoBlockProps = {
  page: TrainingPageNode | SubscriptionPageNode
  isTraining: boolean
  showDatesLink?: boolean
  linkedTrainings?: any[]
  linkedSubscriptionTryoutPageId?: string | null
}

type LevelLabel = {
  [key: string]: string
}

const InfoBlock = ({
  page,
  isTraining,
  showDatesLink,
  linkedTrainings,
  linkedSubscriptionTryoutPageId,
}: InfoBlockProps) => {
  const {t} = useTranslation()
  const router = useRouter()
  const courseTypeLabel = isTraining
    ? page?.attributes?.field_course_type_code
    : 'subscription'

  const formattedCourseTypeCode = formattedCourseType(courseTypeLabel)

  const level = page.attributes.field_difficulty_level
  const learningPaceLabel = page.attributes.field_learning_pace
  const certificateBool = (page as TrainingPageNode).attributes
    .field_contain_exam
  const chequesPaymentBool = (page as TrainingPageNode).attributes
    .field_cheques_formation_payment
  const walletPaymentBool = (page as TrainingPageNode).attributes
    .field_kmo_portefeuille_payment
  const memberPrice = page.attributes.field_price_member
  const nonMemberPrice = page.attributes.field_price_non_member
  const isInCompany = (page as TrainingPageNode).attributes.field_is_in_company
  const trainingId = (page as TrainingPageNode).attributes.field_training_id
  const courseTypeCode = page.attributes.field_course_type_code

  const difficultyLevelToLabel: LevelLabel = {
    0: striptags(t('trainingpage_levelnotspecified')),
    1: striptags(t('trainingpage_levelstarter')),
    2: striptags(t('trainingpage_levelmedium')),
    3: striptags(t('trainingpage_levelexpert')),
  }

  return (
    <div className="c-hero-info-block">
      <div className="c-hero-info-block--training-info d-flex flex-column">
        <InfoBlockField
          fieldType="training-type"
          label={courseTypeLabel}
          icon={formattedCourseTypeCode}
        />

        {showDatesLink ? (
          <div className="c-hero-info-date-field d-flex">
            <span className="c-info-block-field-img date"></span>
            <a
              href="#dates"
              aria-label="dates and locations link"
              className="text-underline"
              dangerouslySetInnerHTML={{
                __html: t('trainingpage_dateslocations'),
              }}
            />
          </div>
        ) : null}

        <InfoBlockLanguage
          fieldType="language"
          locale={router.locale}
          linkedTrainings={linkedTrainings}
        />

        <DifficultyLevel
          level={level}
          difficultyLevelToLabel={difficultyLevelToLabel}
        />
      </div>

      <div className="c-hero-info-block--kmo-info d-flex flex-column">
        <InfoBlockField fieldType="time" label={learningPaceLabel} />

        {isTraining ? (
          <>
            <InfoBlockField
              fieldType="certificate"
              label={striptags(t('trainingpage_certificate'))}
              show={certificateBool}
            />
            <InfoBlockField
              fieldType="kmo"
              label={t('trainingpage_chequesformation')}
              show={chequesPaymentBool}
            />
            <InfoBlockField
              fieldType="kmo"
              label={t('trainingpage_kmowallet')}
              show={walletPaymentBool}
            />
          </>
        ) : null}
      </div>

      <InfoBlockPrice
        memberPrice={memberPrice}
        nonMemberPrice={nonMemberPrice}
        isOnSubscriptionPage={!isTraining}
      />

      {isTraining ? (
        <div className="c-hero-info-block--buttons d-flex flex-column">
          <SubscribeInterestedLink
            trainingPage={page as TrainingPageNode}
            courseTypeCode={courseTypeCode}
          />

          {isInCompany && striptags(t('trainingpage_askoffer')) ? (
            <TailorMadeButton trainingId={+trainingId} />
          ) : null}

          <Link247 courseTypeCode={courseTypeCode} />
        </div>
      ) : (
        <div className="c-hero-info-block--buttons d-flex flex-column">
          <SubscribeLinkSubscription
            subscriptionPage={page as SubscriptionPageNode}
            courseTypeCode={courseTypeCode}
            linkedSubscriptionTryoutPageId={linkedSubscriptionTryoutPageId}
          />
        </div>
      )}
    </div>
  )
}

export default InfoBlock
