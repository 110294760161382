import Link from 'next/link'
import {
  getTargetFromRichtext,
  getUrlFromRichtext,
} from '../../../utils/url-from-richtext'
import striptags from 'striptags'
import {useTranslation} from 'next-i18next'
import {formattedCourseType} from '../../../utils/course-type-code'

type InfoBlockFieldProps = {
  fieldType?: string
  label?: string
  show?: boolean
  icon?: string
}

const InfoBlockField = ({
  fieldType,
  label,
  show = true,
  icon,
}: InfoBlockFieldProps) => {
  const {t} = useTranslation()

  const isTrainingTypeField = fieldType === 'training-type'
  const courseType = formattedCourseType(label)

  if (!label || !show) {
    return null
  }
  return (
    <div className="c-info-block-field d-flex">
      <span
        className={`c-info-block-field-img ${
          isTrainingTypeField ? icon : fieldType
        }`}
      ></span>

      {fieldType === 'kmo' ? (
        <Link
          target={getTargetFromRichtext(label)}
          className="text-underline"
          href={getUrlFromRichtext(label)}
        >
          {striptags(label)}
        </Link>
      ) : (
        <p>
          {isTrainingTypeField
            ? striptags(t(`course_type_${courseType}`))
            : courseType}
        </p>
      )}
    </div>
  )
}

export default InfoBlockField
