import {
  SubscriptionPageNode,
  TrainingPageNode,
} from '../../../types/content-types'
import type {Response} from '../../../types/response'
import RichText from '../RichText'
import TrainingCategory from './TrainingCategory'
import Image from 'next/image'
import InfoBlock from './InfoBlock'
import TrainerSection from './TrainerSection'
import fallback from '../../../public/lms-hero-fallback.jpg'
import {useMediaQuery} from '@mui/material'
import InfoBanner from './InfoBanner'
import {useRef} from 'react'
import useIntersectionObserver from '../../../utils/hooks/useIntersectionObserver'

type HeroProps = {
  page: TrainingPageNode | SubscriptionPageNode
  response?: Response | null
  showDatesLink?: boolean
  linkedTrainings?: any[]
  linkedSubscriptionTryoutPageId?: string | null
}

const Hero = ({
  page,
  response,
  showDatesLink,
  linkedTrainings,
  linkedSubscriptionTryoutPageId,
}: HeroProps) => {
  const isLGBreakpoint = useMediaQuery('(min-width: 992px)')
  const title = page.attributes.title
  const isTraining = page.type === 'node--training'
  const description = isTraining
    ? (page as TrainingPageNode).attributes.field_goal_description?.processed
    : page.attributes.field_content_description?.processed
  const trainerData = (page as TrainingPageNode).relationships.field_teacher
    ?.data

  const fieldMedia = response?.included?.find(
    el => el.id === page?.relationships?.field_media?.data?.id,
  )
  const fieldMediaImage = response?.included?.find(
    el => el.id === fieldMedia?.relationships?.field_media_image?.data?.id,
  )

  const subscriptionHeroImage = fieldMediaImage?.attributes?.uri?.url
    ? `${process.env.NEXT_PUBLIC_API_URL}${fieldMediaImage?.attributes?.uri?.url}`
    : fallback
  const trainingPageImage = page.attributes.field_image_url as string
  const isSubscriptionImage =
    page.type === 'node--subscription'
      ? subscriptionHeroImage
      : trainingPageImage

  const backgroundUrl = isSubscriptionImage ? isSubscriptionImage : fallback
  const heroRef = useRef<HTMLDivElement>(null)
  const heroEntry = useIntersectionObserver(heroRef, {})

  const isHeroVisible = heroEntry?.isIntersecting ?? true

  const renderTrainingCategories = () => {
    const categoriesObject = page.relationships.field_category_page?.data?.map(
      el => response?.included?.find(includedItem => includedItem.id === el.id),
    )

    const categoriesItems = categoriesObject?.map(category => {
      const categoryIcon = response?.included?.find(
        el => el.id === category.relationships.field_icon?.data?.id,
      )

      return (
        <TrainingCategory
          key={category.id}
          icon={categoryIcon}
          response={response}
          link={category.attributes.path}
          title={category.attributes.title}
        />
      )
    })

    return categoriesItems
  }

  return (
    <>
      <section className="c-training-hero" ref={heroRef}>
        <div className="c-training-hero-container">
          <div className="c-hero-intro">
            <div className="c-hero-intro--text-container d-flex flex-column">
              <div className="c-training-category-container d-flex">
                {renderTrainingCategories()}
              </div>
              <h1 className="c-hero-intro-title">{title}</h1>
              <RichText
                extraClass="c-hero-intro-description"
                html={description}
              />

              {isLGBreakpoint ? (
                <TrainerSection
                  isTraining={isTraining}
                  trainerData={trainerData}
                  response={response}
                />
              ) : null}
            </div>

            {isLGBreakpoint ? (
              <div className="c-hero-intro--image">
                <Image src={backgroundUrl} alt="hero-image" fill />
              </div>
            ) : null}
          </div>

          <div>
            <InfoBlock
              page={page}
              isTraining={isTraining}
              showDatesLink={showDatesLink}
              linkedTrainings={linkedTrainings}
              linkedSubscriptionTryoutPageId={linkedSubscriptionTryoutPageId}
            />
          </div>
        </div>
      </section>

      {isHeroVisible ? null : (
        <InfoBanner
          bannerTitle={title}
          isTraining={isTraining}
          page={page}
          linkedSubscriptionTryoutPageId={linkedSubscriptionTryoutPageId}
          showDatesLink={showDatesLink}
        />
      )}
    </>
  )
}

export default Hero
