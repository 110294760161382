import {TrainingPageNode} from '../../../types/content-types'
import striptags from 'striptags'
import {useTranslation} from 'next-i18next'

type LanguageProps = {
  fieldType?: string
  label?: string
  locale?: string
  linkedTrainings?: TrainingPageNode[]
}

const InfoBlockLanguage = ({
  fieldType,
  locale,
  linkedTrainings,
}: LanguageProps) => {
  const {t} = useTranslation()
  return (
    <div className="c-info-block-field d-flex">
      <span className={`c-info-block-field-img ${fieldType}`}></span>
      <div className="d-flex">
        <p className="my-0">{t(`trainingsearch_${locale!.split('-')[0]}`)}</p>
        {linkedTrainings?.[0] && (
          <>
            <a
              className="text-underline"
              href={`${process.env.NEXT_PUBLIC_BASEPATH_ALTERNATE}${linkedTrainings[0].attributes.path.alias}`}
            >
              / {striptags(t('trainingpage_switchlanguage'))}
            </a>
          </>
        )}
      </div>
    </div>
  )
}

export default InfoBlockLanguage
