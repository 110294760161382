import GenericLink from '../Link'
import MediaItem from '../MediaItem'
import type {Response} from '../../../types/response'
import {DrupalLink} from '../../../types/content-types'
import {MediaImage} from '../../../types/paragraphs'

type TrainingCategoryProps = {
  icon: MediaImage
  response?: Response | null
  link: DrupalLink
  title: string
}

const TrainingCategory = ({
  icon,
  response,
  link,
  title,
}: TrainingCategoryProps) => {
  return (
    <div className="c-training-category d-flex">
      <MediaItem
        layout="fixed"
        wrapperClass="c-training-category-image"
        item={icon}
        height={24}
        width={24}
        response={response}
      />
      <GenericLink link={link} extraClass={`c-training-category-text`}>
        {title}
      </GenericLink>
    </div>
  )
}

export default TrainingCategory
