import striptags from 'striptags'
import {
  SubscriptionPageNode,
  TrainingPageNode,
} from '../../../types/content-types'
import SubscribeLinkSubscription from '../../content-types/Subscription/SubscribeInterestedLink'
import SubscribeInterestedLink from '../../content-types/TrainingPage/SubscribeInterestedLink'
import TailorMadeButton from '../../content-types/TrainingPage/TailorMadeButton'
import {useTranslation} from 'next-i18next'
import {useMediaQuery} from '@mui/material'
import {formattedCourseType} from '../../../utils/course-type-code'
import InfoBlockField from './InfoBlockField'
import InfoBlockPrice from './InfoBlockPrice'

type InfoBannerProps = {
  bannerTitle: string
  isTraining?: boolean
  page: TrainingPageNode | SubscriptionPageNode
  linkedSubscriptionTryoutPageId?: string | null
  showDatesLink?: boolean
}

const InfoBanner = ({
  bannerTitle,
  isTraining,
  page,
  linkedSubscriptionTryoutPageId,
  showDatesLink,
}: InfoBannerProps) => {
  const {t} = useTranslation()

  const isInCompany = (page as TrainingPageNode).attributes.field_is_in_company
  const courseTypeCode = page.attributes.field_course_type_code
  const trainingId = (page as TrainingPageNode).attributes.field_training_id

  const isLGBreakpoint = useMediaQuery('(min-width: 992px)')

  const courseTypeLabel = isTraining
    ? page?.attributes?.field_course_type_code
    : 'subscription'

  const formattedCourseTypeCode = formattedCourseType(courseTypeLabel)

  const learningPaceLabel = page.attributes.field_learning_pace
  const memberPrice = page.attributes.field_price_member
  const nonMemberPrice = page.attributes.field_price_non_member

  return (
    <div className={`c-info-banner`}>
      <p className="c-info-banner--title ">{bannerTitle}</p>
      <div className="c-info-banner-container">
        {isLGBreakpoint ? (
          <div className="c-info-banner-content">
            <InfoBlockField
              fieldType="training-type"
              label={courseTypeLabel}
              icon={formattedCourseTypeCode}
            />

            {showDatesLink ? (
              <div className="c-hero-info-date-field d-flex">
                <span className="c-info-block-field-img date"></span>
                <a
                  href="#dates"
                  aria-label="dates and locations link"
                  className="text-underline"
                  dangerouslySetInnerHTML={{
                    __html: t('trainingpage_dateslocations'),
                  }}
                />
              </div>
            ) : null}

            <InfoBlockField fieldType="time" label={learningPaceLabel} />

            <InfoBlockPrice
              memberPrice={memberPrice}
              nonMemberPrice={nonMemberPrice}
              isOnSubscriptionPage={!isTraining}
            />
          </div>
        ) : null}
        {isTraining ? (
          <div className="c-info-banner--buttons">
            <SubscribeInterestedLink
              trainingPage={page as TrainingPageNode}
              courseTypeCode={courseTypeCode}
            />

            {isInCompany && striptags(t('trainingpage_askoffer')) ? (
              <TailorMadeButton trainingId={+trainingId} />
            ) : null}
          </div>
        ) : (
          <div className="c-info-banner--buttons">
            <SubscribeLinkSubscription
              subscriptionPage={page as SubscriptionPageNode}
              courseTypeCode={courseTypeCode}
              linkedSubscriptionTryoutPageId={linkedSubscriptionTryoutPageId}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default InfoBanner
